import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import liff from "@line/liff"; // Import LIFF SDK
import "./App.css"; // ใส่ฟอนต์ 'Kanit'

const MamberManager: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null); // เก็บ userId จาก LIFF
  const [step, setStep] = useState(1);
  const [memberData, setMemberData] = useState<any>(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    province: "",
    district: "",
    postalCode: "",
    bankName: "",
    bankAccount: "",
    distributorType: "",
    image: null as File | null, // ปรับให้เก็บไฟล์หรือค่าว่าง (null)
  });

  const [memberId, setMemberId] = useState("");
  const [memberPass, setMemberPass] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const loginFetchMemberData = () => {
    // ตรวจสอบว่า memberId และ memberPass มีค่าหรือไม่
    if (!memberId || !memberPass) {
      Swal.fire({
        icon: "warning",
        title: "กรอกข้อมูลไม่ครบ",
        text: "กรุณากรอก Id และ Password ให้ครบถ้วน",
      });
      return; // หยุดการทำงานของฟังก์ชัน
    }

    // แสดงสถานะการโหลด
    Swal.fire({
      title: "Loading...",
      text: "กำลังโหลดข้อมูล กรุณารอสักครู่",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(`https://artelu-api.vercel.app/api/member/profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        referralCode: memberId,
        password: memberPass,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("ข้อมูล Id หรือ Password ไม่ถูกต้อง");
        }
        return response.json();
      })
      .then((data) => {
        // ปิดการแสดง Loading
        Swal.close();

        // แจ้งผลสำเร็จ
        Swal.fire({
          icon: "success",
          title: "สำเร็จ!",
          text: "โหลดข้อมูลสำเร็จ",
        });

        console.log("Member Data:", data);
        // ใส่ data ที่ดึงมาใน state memberData
        setMemberData(data);

        setIsLoggedIn(true);
        // ใช้ข้อมูลในแอปพลิเคชัน
      })
      .catch((error) => {
        // ปิดการแสดง Loading
        Swal.close();

        // แจ้งข้อผิดพลาด
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.message || "ไม่สามารถโหลดข้อมูลได้",
        });

        console.error("Error fetching member data:", error);
      });
  };

  // Initialize LIFF และดึง userId
  /* useEffect(() => {
    liff.init({ liffId: '2006373201-Y0pwJ7Z9' }) // ใส่ LIFF ID ของคุณ
      .then(() => {
        if (liff.isLoggedIn()) {
          liff.getProfile().then(profile => {
            setUserId(profile.userId);  // เก็บ userId ลงใน state
            
          }).catch(err => {
            console.error('Error getting profile: ', err);
          });
        } else {
          liff.login();  // ถ้ายังไม่ได้ล็อกอิน ให้พาไปที่หน้าล็อกอิน
        }
      })
      .catch(err => {
        console.error('LIFF initialization failed: ', err);
      });
  }, []);*/

  const updateFormData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="container mt-5 mb-5">
      {!isLoggedIn ? (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="cards p-4 shadow">
              <h2 className="text-center mb-4">Member Login</h2>
              <div className="mb-3">
                <label>ID:</label>
                <input
                  type="text"
                  className="form-control"
                  value={memberId}
                  onChange={(e) => setMemberId(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>Password:</label>
                <input
                  type="password"
                  className="form-control"
                  value={memberPass}
                  onChange={(e) => setMemberPass(e.target.value)}
                />
              </div>
              <button
                onClick={loginFetchMemberData}
                className="btn btn-primary w-100"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="cards p-4 shadow">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0">
                  Profile ID: {memberData[0]?.["refferal-code"]}
                </h5>
                <button className="btn btn-outline-secondary btn-sm">
                  {memberData[0]?.["distributorType"] === "1"
                    ? "ตัวแทนจำหน่าย"
                    : memberData[0]?.["distributorType"] === "2"
                    ? "ศิลปิน"
                    : memberData[0]?.["distributorType"] === "3"
                    ? "หมอดู"
                    : "ไม่ระบุ"}
                </button>
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    ชื่อ: {memberData[0]?.user?.name}{" "}
                    {memberData[0]?.user?.lastname}
                  </span>
                  <span className="text-success">active</span>
                </div>
                <div>
                  <button className="btn btn-link btn-sm p-0">edit</button>
                </div>
              </div>
              <hr />
              <h6 className="mb-3">Income</h6>
              <p>รายได้ทั้งหมด: 8888 บาท</p>
              <hr />
              <h6 className="mb-3">Total</h6>
              <div className="bg-light p-3 rounded">
                {/* Total content goes here */}
                <p className="text-center text-muted">ยังไม่มีข้อมูล</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MamberManager;
