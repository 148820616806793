import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const FormPage: React.FC<{
  nextStep: () => void;
  prevStep: () => void; // เพิ่ม prevStep
  updateFormData: (data: any) => void;
  formData: any;
}> = ({ nextStep, prevStep, updateFormData, formData }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [prayer, setPrayer] = useState("");
  const [isWednesdayNight, setIsWednesdayNight] = useState(false);
  const [showWednesdayNightOption, setShowWednesdayNightOption] =
    useState(false);

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() + 543 - i
  );

  const calculateDayOfWeek = (
    day: number,
    month: number,
    year: number
  ): number => {
    const birthDate = new Date(year - 543, month - 1, day);
    return birthDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
  };

  const handleDateChange = () => {
    if (day && month && year) {
      const dayNumber = calculateDayOfWeek(
        parseInt(day),
        parseInt(month),
        parseInt(year)
      );
      if (dayNumber === 3) {
        // ถ้าเป็นวันพุธ
        setShowWednesdayNightOption(true);
      } else {
        setShowWednesdayNightOption(false);
        setIsWednesdayNight(false); // Reset ค่าเมื่อไม่ใช่วันพุธ
      }
    } else {
      setShowWednesdayNightOption(false);
    }
  };

  useEffect(() => {
    handleDateChange();
  }, [day, month, year]);

  const handleSubmit = () => {
    if (firstName && lastName && day && month && year && prayer) {
      const dayNumber = calculateDayOfWeek(
        parseInt(day),
        parseInt(month),
        parseInt(year)
      );
      const isWednesday = dayNumber === 3; // 3 คือวันพุธ

      const finalIsWednesdayNight = isWednesday ? isWednesdayNight : false;

      updateFormData({
        firstName,
        lastName,
        day,
        month,
        year,
        prayer,
        isWednesdayNight: finalIsWednesdayNight,
      });
      nextStep();
    } else {
      Swal.fire({
        icon: "error",
        title: "ข้อมูลไม่ครบถ้วน",
        text: "กรุณากรอกข้อมูลให้ครบทุกช่อง",
      });
    }
  };

  return (
    <div className="app">
      <div className="containers">
        <h2 className="mb-4 text-center">ข้อมูลสำหรับรูปภาพ</h2>

        <div className="container mt-4">
          {/* ชื่อ */}
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              ชื่อ
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="ชื่อ"
              aria-label="FirstName"
              aria-describedby="basic-addon1"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          {/* นามสกุล */}
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon2">
              นามสกุล
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="นามสกุล"
              aria-label="LastName"
              aria-describedby="basic-addon2"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="row">
            {/* วัน */}
            <div className="col">
              <div className="input-group">
                <select
                  className="form-control text-muted"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  aria-describedby="day-addon"
                >
                  <option value="">วัน</option>
                  {days.map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* เดือน */}
            <div className="col">
              <div className="input-group">
                <select
                  className="form-control text-muted"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  aria-describedby="month-addon"
                >
                  <option value="">เดือน</option>
                  {months.map((m) => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* ปี */}
            <div className="col">
              <div className="input-group">
                <select
                  className="form-control text-muted"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  aria-describedby="year-addon"
                >
                  <option value="">ปี</option>
                  {years.map((y) => (
                    <option key={y} value={y}>
                      {y}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* เช็คพุธกลางคืน */}
          {showWednesdayNightOption && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isWednesdayNight}
                onChange={(e) => setIsWednesdayNight(e.target.checked)}
              />
              <label className="form-check-label">พุธ(กลางคืน)</label>
            </div>
          )}

          <p className="text-muted">
            - ใช้ประกอบการสร้างรูปภาพเท่านั้น
            ไม่มีการจัดเก็บหรือเปิดเผยข้อมูลส่วนบุคคลในรูปภาพ
          </p>
          <hr className="my-3" />

          {/* เลือกการขอพร */}
          <label className="label-wish">ขอพรอธิษฐานจิต</label>
          <div
            className="btn-group-vertical w-100 p-2"
            role="group"
            aria-label="Vertical radio toggle button group"
          >
            <input
              type="radio"
              className="btn-check"
              name="prayer"
              id="vbtn-radio1"
              autoComplete="off"
              checked={prayer === "ความรัก มหาเสน่ห์"}
              onChange={() => setPrayer("ความรัก มหาเสน่ห์")}
            />
            <label
              className="btn btn-outline-dark bg-drak"
              htmlFor="vbtn-radio1"
            >
              ความรัก มหาเสน่ห์
            </label>

            <input
              type="radio"
              className="btn-check"
              name="prayer"
              id="vbtn-radio2"
              autoComplete="off"
              checked={prayer === "โชคลาภ เสี่ยงโชค"}
              onChange={() => setPrayer("โชคลาภ เสี่ยงโชค")}
            />
            <label
              className="btn btn-outline-dark bg-drak"
              htmlFor="vbtn-radio2"
            >
              โชคลาภ เสี่ยงโชค
            </label>

            <input
              type="radio"
              className="btn-check"
              name="prayer"
              id="vbtn-radio3"
              autoComplete="off"
              checked={prayer === "สุขภาพ สติปัญญา"}
              onChange={() => setPrayer("สุขภาพ สติปัญญา")}
            />
            <label
              className="btn btn-outline-dark bg-drak"
              htmlFor="vbtn-radio3"
            >
              สุขภาพ สติปัญญา
            </label>

            <input
              type="radio"
              className="btn-check"
              name="prayer"
              id="vbtn-radio4"
              autoComplete="off"
              checked={prayer === "อำนาจ บารมี"}
              onChange={() => setPrayer("อำนาจ บารมี")}
            />
            <label
              className="btn btn btn-outline-dark bg-drak"
              htmlFor="vbtn-radio4"
            >
              อำนาจ บารมี
            </label>

            <input
              type="radio"
              className="btn-check"
              name="prayer"
              id="vbtn-radio5"
              autoComplete="off"
              checked={prayer === "การเงิน การงาน รุ่งเรือง"}
              onChange={() => setPrayer("การเงิน การงาน รุ่งเรือง")}
            />
            <label
              className="btn btn btn-outline-dark bg-drak"
              htmlFor="vbtn-radio5"
            >
              การเงิน การงาน รุ่งเรือง
            </label>
          </div>

          <p className="text-muted">- เลือก 1 หัวข้อ</p>

          <div className="d-flex justify-content-between">
            {/* ปุ่มกลับ */}
            <img
              src="/images/bt_back1.png"
              alt="กลับ"
              style={{ cursor: "pointer", width: "65px", height: "auto" }}
              onClick={prevStep}
            />

            {/* ปุ่มต่อไป */}
            <img
              src="/images/bt_next1.png"
              alt="ต่อไป"
              style={{ cursor: "pointer", width: "65px", height: "auto" }}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPage;
