// src/CheckListDescriptionPage.tsx
import React from "react";
import { tarotDeck, TarotCard } from "./tarotDeck";
import "./CheckListDescriptionPage.css";

const CheckListDescriptionPage: React.FC = () => {
  return (
    <div className="container-lage">
      <h1>Tarot Cards Checklist</h1>
      <ul className="tarot-list">
        {tarotDeck.map((card: TarotCard, index: number) => (
          <li key={card.id} className="tarot-item">
            <div className="tarot-index">{index + 1}</div>
            <img src={card.image} alt={card.name} className="tarot-image" />
            <div className="tarot-details">
              <h3>{card.name}</h3>
              <p>{card.description}</p>
              <i>ศิลปิน ผู้ออกแบบไพ่ชุด ART OF DECK "{card.artist}"</i>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CheckListDescriptionPage;
