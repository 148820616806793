import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import liff from '@line/liff'; // ตรวจสอบให้แน่ใจว่าคุณได้ติดตั้ง liff SDK แล้ว

const ImagePage: React.FC<{
  formData: any;
  generatedImage: string;
  watermarkedImage: string;
  zodiacNameEn: string;
  prevStep: () => void; // เพิ่ม prevStep
}> = ({
  formData,
  generatedImage,
  watermarkedImage,
  zodiacNameEn,
  prevStep,
}) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const fileName = `arttelu_laksmi_${zodiacNameEn}_${currentDate}.jpg`;

  // ถ้าการชำระเงินสำเร็จ ให้ใช้ภาพจริง ไม่มีลายน้ำ
  // ถ้าการชำระเงินไม่สำเร็จ ให้ใช้ภาพที่มีลายน้ำ
  const finalImage = watermarkedImage;
  const finalImageReal = generatedImage;

  useEffect(() => {
    // เรียก liff.init() เพื่อให้แน่ใจว่า LIFF SDK พร้อมใช้งาน
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId: '2006373201-OGnp2WAN' });
        console.log("LIFF initialized successfully");
      } catch (error) {
        console.error("Error initializing LIFF:", error);
      }
    };
    
    initializeLiff();
  }, []);



  return (
    <div className="app d-flex align-items-center justify-content-center vh-100">
      <div className="containers p-5  text-center">
        <h2 className="mb-4">การชำระเงินเสร็จสมบูรณ์</h2>
        <p className="text-muted mb-4">
          ภาพพระแม่ลักษมีได้จัดส่งไปที่ Line ของท่านเรียบร้อย
        </p>
        <div className="image-wrapper">
          <img
            src={finalImage}
            alt="Generated"
            style={{ width: "50%", borderRadius: "10px" }}
          />
        </div>
        <div className="d-flex justify-content-between">
        <div className="button-wrapper">
          <img
            src="/images/bt_buy2.png"
            alt="ต่อไป"
            style={{ cursor: "pointer", width: "120px", height: "auto" }}
            onClick={() => {
              window.location.href = "/";
            }}
          />
        </div>
        <div className="button-wrapper">
          <img
            src="/images/bt_close1.png"
            alt="ต่อไป"
            style={{ cursor: "pointer", width: "120px", height: "auto" }}
            onClick={() => {
              if (liff.isInClient()) {
                liff.closeWindow();
              }
            }}
          />
        </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePage;
