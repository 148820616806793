// App.tsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BackEndManager.css";

interface Item {
  itemId: string;
  itemName: string;
  itemPrice: number;
  ownerId: string;
}

const BackEndItemManager: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [newItem, setNewItem] = useState<Item>({
    itemId: "",
    itemName: "",
    itemPrice: 0,
    ownerId: "",
  });

  const [page, setPage] = useState<number>(1);

  // Fetch items from backend
  const fetchItems = () => {
    fetch("https://artelu-api.vercel.app/api/admin/backEndManager/getItems")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // แปลงผลลัพธ์เป็น JSON
      })
      .then((data) => {
        console.log("Fetched items:", data); // ตรวจสอบข้อมูล
        setItems(data); // ตั้งค่า items
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
        setItems([]); // ตั้งค่า items เป็น array ว่างในกรณีเกิดข้อผิดพลาด
      });
  };
  

  
  
  

  // Create new item
  const handleCreateItem = async () => {
    if (
      !newItem.itemId ||
      !newItem.itemName ||
      !newItem.itemPrice ||
      !newItem.ownerId
    ) {
      Swal.fire("Error", "Please fill in all fields", "error");
      return;
    }

    try {
      const confirmed = await Swal.fire({
        title: "คุณแน่ใจที่จะสร้างสินค้าใหม่?",
        text: "แน่ใจกดตกลง ไม่แน่ใจกดยกเลิก",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง, create it!",
        cancelButtonText: "ยกเลิก, cancel!",
      });

      if (confirmed.isConfirmed) {
        await axios.post(
          "https://artelu-api.vercel.app/api/admin/backEndManager/createNewItem",
          newItem
        );
        Swal.fire("Success", "Item created successfully!", "success");
        fetchItems(); // Refresh item list
      }
    } catch (error) {
      Swal.fire("Error", "กรอกข้อมูลให้ครบ", "error");
    }
  };

  const handleDeleteItem = async (itemId: string) => {
    try {
      // แสดงการยืนยันก่อนลบ
      const confirmed = await Swal.fire({
        title: "คุณแน่ใจที่จะลบสินค้านี้?",
        text: "สินค้าจะถูกลบออกจากระบบและไม่สามารถกู้คืนได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่, ลบเลย!",
        cancelButtonText: "ยกเลิก",
      });

      // ถ้าผู้ใช้ยืนยัน
      if (confirmed.isConfirmed) {
        await axios.delete("https://artelu-api.vercel.app/api/admin/backEndManager/removeItem", {
          data: { itemId }, // ส่ง itemId ใน request body
        });

        // แสดงข้อความสำเร็จ
        Swal.fire("ลบสำเร็จ!", "สินค้าถูกลบออกจากระบบแล้ว", "success");

        // ดึงรายการสินค้าล่าสุด
        fetchItems();
      }
    } catch (error) {
      // แสดงข้อความข้อผิดพลาด
      Swal.fire("ลบไม่สำเร็จ", "เกิดข้อผิดพลาดในการลบสินค้า", "error");
      console.error("Error deleting item:", error);
    }
  };
  
  

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div className="container mt-4 fontArtteluh mt-5">
      <h2 className="text-center mb-4">จัดการข้อมูลหลังบ้าน Artteluh</h2>
      {/* Navigation Buttons */}
      <div className="container ">
        <div className="d-flex justify-content-left mt-5">
          <button
            className={`btn mx-2 ${
              page === 1 ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setPage(1)}
          >
            ข้อมูลสมาชิก
          </button>
          <button
            className={`btn mx-2 ${
              page === 2 ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setPage(2)}
          >
            ข้อมูลสินค้า
          </button>
          <button
            className={`btn mx-2 ${
              page === 3 ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setPage(3)}
          >
            สินค้าที่ขายแล้ว
          </button>
          <button
            className={`btn mx-2 ${
              page === 4 ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setPage(4)}
          >
            ตาราหมอดู
          </button>
        </div>

        <div className="container mt-5 p-4 bg-light">
          {/* Render Selected Page */}
          {page === 1 ? (
            <div>
              <h3>Users Management</h3>
              <p>Manage users here...</p>
            </div>
          ) : page === 2 ? (
            <div>
              <h2>สินค้าปัจุบัน</h2>
              <hr />
              <div className="container mb-4">
                <h5 className="card-header mb-2">ช่องสร้างสินค้าใหม่</h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <label>Item ID(รหัสสินค้า)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newItem.itemId}
                        onChange={(e) =>
                          setNewItem({ ...newItem, itemId: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Item Name(ชื่อสินค้า)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newItem.itemName}
                        onChange={(e) =>
                          setNewItem({ ...newItem, itemName: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Item Price(ตั้งราคาขาย)</label>
                      <input
                        type="number"
                        className="form-control"
                        value={newItem.itemPrice}
                        onChange={(e) =>
                          setNewItem({
                            ...newItem,
                            itemPrice: parseFloat(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Owner ID(สมาชิกที่เป็นเจ้าของ)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newItem.ownerId}
                        onChange={(e) =>
                          setNewItem({ ...newItem, ownerId: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleCreateItem}
                  >
                    Create Item(สร้างสินค้า)
                  </button>
                </div>
              </div>
              <hr />
              <div className="container cards">
                <h5 className="card-header mb-2">แสดงข้อมูลสินค้าที่มี</h5>
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Item ID</th>
                        <th>Item Name</th>
                        <th>Item Price</th>
                        <th>Owner ID</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(items) &&
                        items.map((item) => (
                          <tr key={item.itemId}>
                            <td>{item.itemId}</td>
                            <td>{item.itemName}</td>
                            <td>{item.itemPrice} บาท</td>
                            <td>{item.ownerId}</td>
                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={() => handleDeleteItem(item.itemId)}
                              >
                                ลบ
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  
                </div>
              </div>
                    {/* แสดงข้อมูล items แบบสด */}
      <div className="mt-4">
        <h5>แสดง item แบบสด (Raw Data):</h5>
        <div style={{ whiteSpace: "pre-wrap", backgroundColor: "#f8f9fa", padding: "10px", borderRadius: "5px" }}>
          {JSON.stringify(items, null, 2)}
        </div>
      </div>
            </div>
            
          ) : page === 3 ? (
            <div>
              <h3>Transaction History</h3>
              <p>View and manage transactions here...</p>
            </div>
          ) : page === 4 ? (
            <div>
              <h3>Fortune Calendar</h3>
              <p>View and manage your calendar here...</p>
            </div>
          ) : (
            <div>
              <h3>Page Not Found</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackEndItemManager;
