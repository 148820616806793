import React from 'react';
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
    const navigate = useNavigate();

    return (
        <div className="app d-flex align-items-center justify-content-center vh-100">
            <div className="containers p-5 text-center">
                <h2 className="mb-4">การชำระเงินถูกยกเลิก</h2>
                <p className="text-muted mb-4">โปรดกลับไปหน้าแรกเพื่อทำการซื้อใหม่</p>
                <button onClick={() => navigate('/')} className="btn btn-primary">กลับไปหน้าแรก</button>
            </div>
        </div>
    );
};

export default CancelPage;
