import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import ClipLoader from "react-spinners/ClipLoader";

const ResultPage: React.FC<{
  formData: any;
  nextStep: (
    image?: string,
    zodiacNameEn?: string,
    watermarkedImage?: string
  ) => void;
  prevStep: () => void; // เพิ่ม prevStep
  setGeneratedImage: (image: string) => void;
  setWatermarkedImage: (image: string) => void;
  updateFormData: (data: any) => void;
}> = ({
  formData,
  nextStep,
  prevStep,
  setGeneratedImage,
  setWatermarkedImage,
  updateFormData,
}) => {
  const [zodiac, setZodiac] = useState("");
  const [generatedImage, setGeneratedImageState] = useState("");
  const [watermarkedImageState, setWatermarkedImageState] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true); // เพิ่มสถานะการโหลดภาพ
  const [showDetails, setShowDetails] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const zodiacNames: { [key: string]: string } = {
    "1": "มังกร",
    "2": "กุมภ์",
    "3": "มีน",
    "4": "เมษ",
    "5": "พฤษภ",
    "6": "เมถุน",
    "7": "กรกฏ",
    "8": "สิงห์",
    "9": "กันย์",
    "10": "ตุลย์",
    "11": "พิจิก",
    "12": "ธนู",
  };

  const zodiacNamesEn: { [key: string]: string } = {
    มังกร: "Capricorn",
    กุมภ์: "Aquarius",
    มีน: "Pisces",
    เมษ: "Aries",
    พฤษภ: "Taurus",
    เมถุน: "Gemini",
    กรกฏ: "Cancer",
    สิงห์: "Leo",
    กันย์: "Virgo",
    ตุลย์: "Libra",
    พิจิก: "Scorpio",
    ธนู: "Sagittarius",
  };

  const flowerDescriptions: { [key: number]: string } = {
    1: '<span style="color:  #e10f0f ;">คุณเกิดวันจันทร์:</span> สีประจำวันของคุณคือ ขาวครีม ดอกไม้ประจำตัวคุณคือดอกบัวซึ่งอยู่ด้านหลังองค์พระแม่ลักษมีและกุญแจดอกบัวใต้ฐานบัวเป็นสัญลักษณ์ประจำตัวคุณดอกบัวแทนความบริสุทธิ์และความสงบสุข จิตใจที่สงบและมุ่งมั่นในความดีงาม การมีความศรัทธาในตนเองและการทำดีเสมอ คุณมีความตั้งใจในการทำสิ่งที่ดีเพื่อผู้อื่น และมักจะพบกับความสงบสุขในชีวิต ด้วยความมั่นคงทางจิตใจ คุณสามารถเผชิญหน้ากับความท้าทายอย่างสงบและมั่นใจ',
    2: '<span style="color:  #e10f0f ;">คุณเกิดวันอังคาร:</span> สีประจำวันของคุณ คือชมพูหรือส้ม ดอกไม้ประจำตัวคุณคือดอกโกสนอยู่ด้านหลังองค์พระแม่ลักษมีและกุญแจดอกบัวใต้ฐานบัวเป็นสัญลักษณ์ประจำตัวคุณดอกโกสนเป็นตัวแทนแห่งความมั่นคงและความเจริญรุ่งเรือง บ่งบอกถึงความหลากหลายและความงดงามของชีวิตคุณ มีแนวโน้มที่จะมีชีวิตที่เต็มไปด้วยความเปลี่ยนแปลงและความหลากหลาย ดอกโกสนชี้ให้เห็นว่าคุณเป็นคนที่สามารถปรับตัวได้ดีในทุกสถานการณ์ และมักจะได้รับความสำเร็จและความมั่นคงในชีวิต',
    3: '<span style="color:  #e10f0f ;">คุณเกิดวันพุธ(กลางวัน):</span> สีประจำวันของคุณ คือสีเขียว ดอกไม้ประจำตัวคุณคือดอกแก้วอยู่ด้านหลังองค์พระแม่ลักษมีและกุญแจดอกแก้วใต้ฐานบัวเป็นสัญลักษณ์ประจำตัวคุณดอกแก้วเป็นตัวแทนของความบริสุทธิ์และความสงบสุข บ่งบอกถึงความอ่อนโยนและความสง่างามในตัวคุณ สะท้อนถึงบุคลิกที่อ่อนโยน มีเมตตา และรักความสงบสุข คุณเป็นคนที่สามารถนำพาความสงบและความสมดุลมาสู่ชีวิตของตนเองและผู้อื่น',
    4: '<span style="color:  #e10f0f ;">คุณเกิดวันพฤหัสบดี:</span> สีประจำวันของคุณคือเหลืองหรือส้มอ่อน ดอกไม้ประจำตัวคุณคือดอกกุหลาบสีเหลืองอยู่ด้านหลังองค์พระแม่ลักษมีและกุญแจดอกกุหลาบสีเหลืองเป็นสัญลักษณ์ประจำตัวคุณดอกกุหลาบสีเหลืองตัวแทนของความสวยงาม ความรัก และความสดชื่นและสะท้อนถึงบุคลิกที่สง่างามและมีความรักในตนเอง คุณมักจะมีเสน่ห์ที่ทำให้คนรอบข้างรู้สึกดีและนับถือคุณ คุณจะได้รับความรักและความนับถือจากผู้คนรอบข้าง',
    5: '<span style="color:  #e10f0f ;">คุณเกิดวันศุกร์:</span> สีประจำวันของคุณ คือขาวหรือชมพูอ่อน ดอกไม้ประจำตัวคุณคือดอกจำปีอยู่ด้านหลังองค์พระแม่ลักษมีและกุญแจดอกจำปีเป็นสัญลักษณ์ประจำตัวคุณดอกจำปีเป็นสัญลักษณ์ของความงามและความอ่อนโยน มีจิตใจที่อ่อนโยนและรักความสงบสุข ดอกจำปีบ่งบอกถึงความรักและความอ่อนหวานในตัวคุณ คุณมีเสน่ห์ที่ทำให้คนรอบข้างรู้สึกอบอุ่นและสบายใจ',
    6: '<span style="color:  #e10f0f ;">คุณเกิดวันเสาร์:</span> สีประจำวันของคุณ คือม่วงหรือดำ ดอกไม้ประจำตัวคุณคือดอกพวงแสดอยู่ด้านหลังองค์พระแม่ลักษมีและกุญแจดอกพวงแสดเป็นสัญลักษณ์ประจำตัวคุณดอกพวงแสดเป็นตัวแทนของความเข้มแข็งและความเจริญรุ่งเรือง การมีจิตใจที่เข้มแข็งและไม่ยอมแพ้ต่อความยากลำบาก ดอกพวงแสดบ่งบอกถึงความโชคดีและความสวยงามในชีวิตของคุณ คุณจะสามารถเอาชนะอุปสรรคได้เสมอและก้าวไปสู่ความสำเร็จ',
    7: '<span style="color:  #e10f0f ;">คุณเกิดวันอาทิตย์:</span> สีประจำวันของคุณ คือสีแดง.ดอกไม้ประจำตัวคุณคือดอกทานตะวันซึ่งอยู่ด้านหลังองค์พระแม่ลักษมีและกุญแจดอกทานตะวันใต้ฐานบัวเป็นสัญลักษณประจำตัวคุณ ดอกทานตะวันตัวแทนแห่งความร่าเริงและความสดใส แสงสว่างแห่งชีวิตที่เต็มไปด้วยพลังและความกระตือรือร้น มองโลกในแง่ดี ไม่ยอมแพ้ต่ออุปสรรค และมีความเชื่อมั่นในตนเองสูง คุณจะประสบความสำเร็จในสิ่งที่คุณตั้งใจทำ เพราะคุณไม่เคยหยุดแสวงหาความสุขและความสำเร็จ',
    8: '<span style="color:  #e10f0f ;">คุณเกิดวันพุธ(กลางคืน):</span> สีประจำวันของคุณ คือดำหรือน้ำเงินเข้ม ดอกไม้ประจำตัวคุณคือดอกดอกพุดอยู่ด้านหลังองค์พระแม่ลักษมีและกุญแจดอกพุดเป็นสัญลักษณ์ประจำตัวคุณดอกพุดเป็นตัวแทนความเจริญรุ่งเรืองและความมั่นคง ชีวิตที่สมบูรณ์แบบ และมักจะได้รับความมั่นคงและความสงบสุขในชีวิต ดอกพุดยังบ่งบอกถึงความสงบสุขและความสมดุลในจิตใจ ทำให้คุณสามารถเผชิญกับความยากลำบากได้อย่างมั่นคง',
  };

  const diamondDescriptions: { [key: number]: string } = {
    1: `<span style="color: #000000;">อัญมณีที่เครื่องทรงประจำองค์พระแม่ลักษมีคือ</span> ไข่มุกหรือมูนสโตน ไข่มุกและมูนสโตนจะช่วยเสริมสร้างความสงบสุข ความเมตตา และความเข้าใจผู้อื่น การสวมใส่หรือใช้สีขาวหรือครีมในชีวิตประจำวันจะช่วยให้คุณมีจิตใจที่เยือกเย็นและสามารถเข้าใจความรู้สึกของผู้อื่นได้ดี นอกจากนี้ยังเสริมความเป็นมิตรและการให้ความช่วยเหลือแก่ผู้อื่นได้อย่างไม่มีเงื่อนไข <span style="color: #000000;"></br>กงจักรด้านหลังเป็นราศี${zodiac}ซึ่งเป็นราศีประจำปีเกิดของคุณ </span></br>สำหรับคนเกิดวันจันทร์ <i style="color: #000000;">เลขกาลกิณีคือเลข 1</i>`,
    2: `<span style="color: #000000;">อัญมณีที่เครื่องทรงประจำองค์พระแม่ลักษมีคือ</span> ปะการังแดงหรือโกเมนสีส้ม ปะการังแดงและโกเมนสีส้มเป็นสัญลักษณ์ของความแข็งแกร่งและความกล้า สีชมพูหรือส้มช่วยเสริมความตัดสินใจที่เด็ดขาดและความพร้อมที่จะเผชิญกับความท้าทาย คุณเป็นคนที่ไม่กลัวต่ออุปสรรค และมักจะตัดสินใจได้อย่างรวดเร็วและแน่วแน่ การเลือกใช้อัญมณีและสีเหล่านี้จะช่วยเพิ่มความมั่นใจในตัวเอง และดึงดูดพลังงานที่ดีเข้ามาในชีวิต<span style="color: #000000;"></br>กงจักรด้านหลังเป็นราศี${zodiac}ซึ่งเป็นราศีประจำปีเกิดของคุณ </span></br>สำหรับคนเกิดวันอังคาร <i style="color: #000000;">เลขกาลกิณีคือเลข 2</i>`,
    3: `<span style="color: #000000;">อัญมณีที่เครื่องทรงประจำองค์พระแม่ลักษมีคือ</span> มรกตหรือหยก มรกตและหยกช่วยเสริมสร้างความมั่งคั่งและความเจริญรุ่งเรือง การสวมใส่อัญมณีหรือใช้สีเขียวในชีวิตประจำวันจะช่วยให้คุณมีโชคลาภและประสบความสำเร็จในด้านการงานและการเงิน อีกทั้งยังช่วยเสริมสร้างความสัมพันธ์ที่ดีและการสื่อสารที่มีประสิทธิภาพ<span style="color: #000000;"></br>กงจักรด้านหลังเป็นราศี${zodiac}ซึ่งเป็นราศีประจำปีเกิดของคุณ </span></br>สำหรับคนเกิดวันพุธ(กลางวัน) <i style="color: #000000;">เลขกาลกิณีคือเลข 3</i>`,
    4: `<span style="color: #000000;">อัญมณีที่เครื่องทรงประจำองค์พระแม่ลักษมีคือ</span> บุษราคัมหรือแซฟไฟร์สีเหลือง บุษราคัมและแซฟไฟร์สีเหลืองเป็นสัญลักษณ์ของความรู้และสติปัญญา มีความสามารถในการเรียนรู้และตัดสินใจได้อย่างถูกต้อง สีเหลืองหรือส้มอ่อนช่วยเสริมสร้างความเฉลียวฉลาดและการตัดสินใจที่ดี การเลือกใช้อัญมณีและสีเหล่านี้จะช่วยให้คุณมีความชัดเจนในการคิดและสามารถรับมือกับสถานการณ์ต่างๆ ได้อย่างมีประสิทธิภาพ<span style="color: #000000;">กงจักรด้านหลังเป็นราศี${zodiac}ซึ่งเป็นราศีประจำปีเกิดของคุณ </span></br>สำหรับคนเกิดวันพฤหัสบดี <i style="color: #000000;">เลขกาลกิณีคือเลข 7</i>`,
    5: `<span style="color: #000000;">อัญมณีที่เครื่องทรงประจำองค์พระแม่ลักษมีคือ</span> เพชรหรือควอตซ์ใส เพชรและควอตซ์ใสเป็นสัญลักษณ์ของความเป็นศิริมงคลและความงาม สีขาวหรือชมพูอ่อนช่วยเสริมสร้างความรักและความสัมพันธ์ที่ดี การเลือกใช้อัญมณีและสีเหล่านี้จะทำให้คุณดึงดูดความรักและความเมตตาจากผู้คนรอบข้าง และเสริมสร้างความงามในตัวคุณทั้งภายในและภายนอก<span style="color: #000000;"></br>กงจักรด้านหลังเป็นราศี${zodiac}ซึ่งเป็นราศีประจำปีเกิดของคุณ </span></br>สำหรับคนเกิดวันศุกร์ <i style="color: #000000;">เลขกาลกิณีคือเลข 8</i>`,
    6: `<span style="color: #000000;">อัญมณีที่เครื่องทรงประจำองค์พระแม่ลักษมีคือ</span> นิลหรือไพลินสีดำ นิลและไพลินสีดำเป็นสัญลักษณ์ของความแข็งแกร่งและการป้องกัน สีม่วงหรือดำช่วยเสริมสร้างความมั่นคงและความเข้มแข็ง การเลือกใช้อัญมณีและสีเหล่านี้จะช่วยให้คุณมีความปลอดภัยและสามารถเผชิญกับความยากลำบากได้อย่างมีประสิทธิภาพ<span style="color: #000000;"></br>กงจักรด้านหลังเป็นราศี${zodiac}ซึ่งเป็นราศีประจำปีเกิดของคุณ </span></br>สำหรับคนเกิดวันเสาร์ <i style="color: #000000;">เลขกาลกิณีคือเลข 4</i>`,
    7: `<span style="color: #000000;">อัญมณีที่เครื่องทรงประจำองค์พระแม่ลักษมีคือ</span> ทับทิมสีแดง  ทับทิมสีแดงเป็นสัญลักษณ์ของความมั่นใจ ความกล้าหาญ และความเป็นผู้นำ ความหมายเหล่านี้บ่งบอกถึงบุคคลที่มีพลังและความมั่นใจในตัวเอ-ง มีความสามารถในการนำทางผู้อื่นและเผชิญหน้ากับความท้าทายอย่างไม่มีความกลัว การเลือกใช้อัญมณีและสีเหล่านี้ จะช่วยเสริมพลังบวกในชีวิต และดึงดูดความสำเร็จและความมั่นใจในทุกๆ ด้าน<span style="color: #000000;"></br>กงจักรด้านหลังเป็นราศี${zodiac}ซึ่งเป็นราศีประจำปีเกิดของคุณ </span></br>สำหรับคนเกิดวันอาทิตย์ <i style="color: #000000;">เลขกาลกิณีคือเลข 6</i>`,
    8: `<span style="color: #000000;">อัญมณีที่เครื่องทรงประจำองค์พระแม่ลักษมีคือ</span> ไพลินหรือลาพิส ลาซูลี ไพลินและลาพิส ลาซูลีเป็นสัญลักษณ์ของความสงบสุขภายในและการฝันที่ดี สีดำหรือน้ำเงินเข้มช่วยเสริมสมาธิและความคิดที่ลึกซึ้ง คุณเป็นคนที่มีจิตใจเข้มแข็งและสามารถควบคุมอารมณ์ของตัวเองได้ดี การสวมใส่อัญมณีเหล่านี้หรือใช้สีที่สอดคล้องกับวันเกิดจะช่วยให้คุณมีสมาธิในการทำงาน และมีความฝันที่สดใสและสร้างสรรค์<span style="color: #000000;"></br>กงจักรด้านหลังเป็นราศี${zodiac}ซึ่งเป็นราศีประจำปีเกิดของคุณ </span></br>สำหรับคนเกิดวันพุธ(กลางคืน) <i style="color: #000000;">เลขกาลกิณีคือเลข 5</i>`,
  };

  const prayerDescriptions: { [key: string]: string } = {
    "ความรัก มหาเสน่ห์":
      '<span style="color: #e10f0f;">คู่เลข 24</span> ที่ฐานบัว เป็นเลขแห่งความรักและความสัมพันธ์ มีเสน่ห์ดึงดูดและเป็นที่ชื่นชอบของคนรอบข้าง คุณมีความสามารถในการสื่อสารและเจรจาต่อรอง ทำให้ประสบความสำเร็จทั้งในด้านการงานและการเงิน นอกจากนี้ยังมีจิตใจเมตตาและสามารถสร้างความสัมพันธ์ที่ดีกับผู้อื่นได้อย่างง่ายดาย คู่เลขนี้ช่วยเสริมสร้างความสุขและความเจริญรุ่งเรืองในชีวิต ทำให้ผู้ที่ใช้เลขนี้มักจะสมหวังและประสบความสำเร็จในหลายด้านของชีวิต',
    "โชคลาภ เสี่ยงโชค":
      '<span style="color: #e10f0f;">คู่เลข 24</span> ที่ฐานบัว จัดอยู่ในกลุ่มเมตตามหานิยมและโชคลาภ เป็นเลขที่เสริมความสำเร็จในด้านความรักและการเสี่ยงโชค ผู้ที่มีเลขนี้จะมีเสน่ห์และเป็นที่รักของคนรอบข้าง สามารถสื่อสารและเจรจาต่อรองได้อย่างมีประสิทธิภาพ เลขนี้ยังช่วยดึงดูดโชคลาภและความสำเร็จ ทำให้ผู้ที่ใช้เลขนี้มีโอกาสในการได้รับสิ่งที่ดีในชีวิต',
    "สุขภาพ สติปัญญา":
      '<span style="color: #e10f0f;">คู่เลข 45</span> ที่ฐานบัว  เป็นเลขที่เสริมสุขภาพและสติปัญญา คุณมีความรอบรู้และความสามารถในการจัดการปัญหาต่างๆ ทำให้สามารถดูแลสุขภาพได้ดี มีพลังงานบวกและความสมดุลในชีวิต การใช้เลขนี้จะช่วยเสริมสร้างสุขภาพที่ดีทั้งกายและใจ รวมถึงมีความสามารถในการคิดวิเคราะห์และตัดสินใจที่ดี เลขนี้ยังช่วยให้คุณสามารถจัดการกับความเครียดและสถานการณ์ยากลำบากได้อย่างมีประสิทธิภาพ',
    "อำนาจ บารมี":
      '<span style="color: #e10f0f;">คู่เลข 89</span> ที่ฐานบัว  เป็นเลขแห่งอำนาจและบารมี มีพลังที่สามารถนำไปสู่ความสำเร็จและความเจริญก้าวหน้าในชีวิต คุณมีความสามารถในการบริหารจัดการและตัดสินใจที่ดี นอกจากนี้ยังมีความสามารถในการแก้ไขปัญหาและผ่านพ้นอุปสรรคต่างๆ ได้อย่างมั่นคงและมีประสิทธิภาพ เลขนี้ยังช่วยเสริมสร้างความน่าเชื่อถือและความไว้วางใจจากคนรอบข้าง ทำให้คุณมีฐานะที่ดีและมั่นคงในชีวิต',
    "การเงิน การงาน รุ่งเรือง":
      '<span style="color: #e10f0f;">คู่เลข 65</span> ที่ฐานบัว  เป็นเลขที่เสริมความมั่นคงในชีวิตทั้งด้านการเงินและความรัก คุณมีความสุขในชีวิตครอบครัวและการงาน มีความสามารถในการบริหารจัดการเงินทองได้ดี และมีโอกาสในการเจริญรุ่งเรืองและประสบความสำเร็จ เลขนี้ช่วยให้คุณมีความสมดุลในชีวิตทั้งด้านการงานและการเงิน ทำให้คุณมีความสุขและมั่นคงในชีวิตทั้งในปัจจุบันและอนาคต',
  };

  const monthNames: { [key: number]: string } = {
    1: "มกราคม",
    2: "กุมภาพันธ์",
    3: "มีนาคม",
    4: "เมษายน",
    5: "พฤษภาคม",
    6: "มิถุนายน",
    7: "กรกฎาคม",
    8: "สิงหาคม",
    9: "กันยายน",
    10: "ตุลาคม",
    11: "พฤศจิกายน",
    12: "ธันวาคม",
  };
  const dayNames: { [key: number]: string } = {
    1: "จันทร์",
    2: "อังคาร",
    3: "พุธกลางวัน",
    4: "พฤหัสบดี",
    5: "ศุกร์",
    6: "เสาร์",
    7: "อาทิตย์",
    8: "พุธกลางคืน", // Add this line
  };

  useEffect(() => {
    if (!isLoading) return;
    const calculateZodiac = (day: number, month: number): string => {
      if ((month === 12 && day >= 16) || (month === 1 && day <= 14))
        return "12"; // ธนู
      if ((month === 1 && day >= 15) || (month === 2 && day <= 12)) return "1"; // มังกร
      if ((month === 2 && day >= 13) || (month === 3 && day <= 14)) return "2"; // กุมภ์
      if ((month === 3 && day >= 15) || (month === 4 && day <= 12)) return "3"; // มีน
      if ((month === 4 && day >= 13) || (month === 5 && day <= 14)) return "4"; // เมษ
      if ((month === 5 && day >= 15) || (month === 6 && day <= 14)) return "5"; // พฤษภ
      if ((month === 6 && day >= 15) || (month === 7 && day <= 14)) return "6"; // เมถุน
      if ((month === 7 && day >= 15) || (month === 8 && day <= 15)) return "7"; // กรกฏ
      if ((month === 8 && day >= 16) || (month === 9 && day <= 16)) return "8"; // สิงห์
      if ((month === 9 && day >= 17) || (month === 10 && day <= 16)) return "9"; // กันย์
      if ((month === 10 && day >= 17) || (month === 11 && day <= 15))
        return "10"; // ตุลย์
      if ((month === 11 && day >= 16) || (month === 12 && day <= 15))
        return "11"; // พิจิก
      return "";
    };

    const getDayOfWeek = (date: Date, isWednesdayNight: boolean) => {
      const dayOfWeek = date.getDay();
      if (isWednesdayNight) {
        return 8; // Return 8 for Wednesday night
      }
      return dayOfWeek === 0 ? 7 : dayOfWeek; // Adjust Sunday to 7
    };

    const getLuckyNumber = (prayer: string): string => {
      switch (prayer) {
        case "ความรัก มหาเสน่ห์":
        case "โชคลาภ เสี่ยงโชค":
          return "24";
        case "สุขภาพ สติปัญญา":
          return "45";
        case "อำนาจ บารมี":
          return "89";
        case "การเงิน การงาน รุ่งเรือง":
          return "65";
        default:
          return "";
      }
    };

    // ตรวจสอบว่ามีข้อมูลใน formData เพียงพอที่จะเริ่มกระบวนการ render
    if (!formData.day || !formData.month || !formData.year) return;

    // คำนวณวันเกิด ราศี และเลขนำโชค
    const day = parseInt(formData.day);
    const month = parseInt(formData.month);
    const year = parseInt(formData.year);
    const birthDate = new Date(year - 543, month - 1, day);
    const isWednesdayNight = formData.isWednesdayNight;
    const dayOfWeek = getDayOfWeek(birthDate, isWednesdayNight);
    setDayOfWeek(dayOfWeek);

    const dayOfWeekForImages = dayOfWeek === 8 ? 3 : dayOfWeek;
    const zodiacSign = calculateZodiac(day, month);
    const luckyNumber = getLuckyNumber(formData.prayer);
    const zodiacName = zodiacNames[zodiacSign];
    const zodiacNameEn = zodiacNamesEn[zodiacName];
    setZodiac(zodiacName);

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (canvas && ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const loadImage = (src: string) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(img);
          img.onerror = (error) => reject(error);
        });
      };

      const loadFont = () => {
        return new Promise<void>((resolve, reject) => {
          const font = new FontFace(
            "DMTwilight",
            "url(/fonts/DM%20Twilight%20Reborn.ttf)"
          );
          font
            .load()
            .then((loadedFont) => {
              document.fonts.add(loadedFont);
              resolve();
            })
            .catch((error) => reject(error));
        });
      };

      const images = [
        `/images/background/bg_${dayOfWeekForImages}.png`,
        `/images/item/it_${dayOfWeekForImages}.png`,
        `/images/zodiac/zd_${zodiacSign}_${dayOfWeekForImages}.png`,
        "/images/main/main.png",
        `/images/clothes/ct_${dayOfWeekForImages}.png`,
        `/images/diamond/dm_${dayOfWeekForImages}.png`,
      ];

      Promise.all([loadFont(), ...images.map(loadImage)])
        .then((results) => {
          const loadedImages = results.slice(1) as HTMLImageElement[];

          // วาดภาพจริงไม่มีลายน้ำ
          ctx.clearRect(0, 0, canvas.width, canvas.height); // ล้าง canvas ก่อนวาดภาพ
          loadedImages.forEach((image, index) => {
            const aspectRatio = image.width / image.height;
            const canvasAspectRatio = canvas.width / canvas.height;
            let drawWidth, drawHeight;

            if (aspectRatio > canvasAspectRatio) {
              drawWidth = canvas.width;
              drawHeight = drawWidth / aspectRatio;
            } else {
              drawHeight = canvas.height;
              drawWidth = drawHeight * aspectRatio;
            }

            const offsetX = (canvas.width - drawWidth) / 2;
            const offsetY = (canvas.height - drawHeight) / 2;

            ctx.drawImage(image, offsetX, offsetY, drawWidth, drawHeight);
          });

          // วาดชื่อและนามสกุลของผู้ใช้
          ctx.font = "80px DMTwilight";
          ctx.fillStyle = "white";
          ctx.textAlign = "center";
          ctx.fillText(
            `${formData.firstName} ${formData.lastName}`,
            canvas.width / 2,
            canvas.height - 30
          );

          // วาดเลขนำโชค
          ctx.font = "100px DMTwilight";
          ctx.fillStyle = "yellow";
          ctx.fillText(luckyNumber, canvas.width / 1.97, canvas.height - 390);

          // บันทึกภาพจริงไม่มีลายน้ำ
          const realImage = canvas.toDataURL("image/jpeg");

          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], "generated_image.jpg", {
                type: "image/jpeg",
              });
              updateFormData({ image: file }); // บันทึก Blob เป็นไฟล์ใน formData
            }
          }, "image/jpeg");

          // วาดภาพมีลายน้ำ
          ctx.clearRect(0, 0, canvas.width, canvas.height); // ล้าง canvas ก่อนวาดภาพ
          loadedImages.forEach((image, index) => {
            const aspectRatio = image.width / image.height;
            const canvasAspectRatio = canvas.width / canvas.height;
            let drawWidth, drawHeight;

            if (aspectRatio > canvasAspectRatio) {
              drawWidth = canvas.width;
              drawHeight = drawWidth / aspectRatio;
            } else {
              drawHeight = canvas.height;
              drawWidth = drawHeight * aspectRatio;
            }

            const offsetX = (canvas.width - drawWidth) / 2;
            const offsetY = (canvas.height - drawHeight) / 2;

            ctx.drawImage(image, offsetX, offsetY, drawWidth, drawHeight);
          });

          // วาดลายน้ำ "Arttelu"
          const text = "Arttelu";
          ctx.font = "60px DMTwilight";
          ctx.fillStyle = "rgba(255, 255, 255, 0.75)"; // สีขาวพร้อมความโปร่งใส
          ctx.textAlign = "center";
          ctx.rotate((Math.PI / 180) * 45); // หมุน canvas 45 องศา

          const textWidth = ctx.measureText(text).width;
          const step = textWidth * 1.5;

          for (let x = -canvas.width; x < canvas.width * 2; x += step) {
            for (let y = -canvas.height; y < canvas.height * 2; y += step) {
              ctx.fillText(text, x, y);
            }
          }

          ctx.rotate(-(Math.PI / 180) * 45); // หมุนกลับ

          // วาดชื่อและนามสกุลของผู้ใช้
          ctx.font = "80px DMTwilight";
          ctx.fillStyle = "white";
          ctx.textAlign = "center";
          ctx.fillText(
            `${formData.firstName} ${formData.lastName}`,
            canvas.width / 2,
            canvas.height - 30
          );

          // วาดเลขนำโชค
          ctx.font = "100px DMTwilight";
          ctx.fillStyle = "yellow";
          ctx.fillText(luckyNumber, canvas.width / 1.97, canvas.height - 390);

          // บันทึกภาพมีลายน้ำ
          const watermarkedImage = canvas.toDataURL("image/jpeg");
          setWatermarkedImage(watermarkedImage);
          setWatermarkedImageState(watermarkedImage);

          // ตั้งค่าภาพที่บันทึกไว้ใน state
          setGeneratedImage(watermarkedImage); // ภาพที่ใช้สำหรับ preview
          setGeneratedImageState(realImage); // ภาพจริงที่ใช้สำหรับดาวน์โหลด

          setIsLoading(false); // การโหลดภาพเสร็จสิ้น
        })
        .catch((err) => {
          console.error("Failed to load images or font", err);
          setIsLoading(false); // กรณีที่เกิดข้อผิดพลาด
        });
    }
  }, [formData]);

  const handleNextStep = () => {
    if (!isLoading) {
      nextStep(generatedImage, zodiac, watermarkedImageState);
    }
  };

  return (
    <div className="app py-5">
      <div className="containers">
        <h2 className="text-center">ข้อมูลสำหรับรูปภาพ</h2>
        <div className="form-groups">
          <div className="canvas-wrapper" onClick={() => setIsModalOpen(true)}>
            <canvas
              ref={canvasRef}
              width={880}
              height={1858}
              style={{ display: "block" }} // แสดง canvas ตลอดเวลา
            ></canvas>
            {isLoading && (
              <div
                style={{
                  backgroundColor: "#ffffff", // สีพื้นหลังของกล่อง
                  padding: "20px", // ระยะขอบภายในกล่อง
                  borderRadius: "15px", // ขอบมน
                  textAlign: "center",
                }}
              >
                <ClipLoader color={"#000000"} loading={isLoading} size={45} />
              </div>
            )}
          </div>
          <div className="pageResultInfo">
            <h2 style={{ textAlign: "left" }}></h2>
            <p style={{ textAlign: "left", marginTop: "-0px" }}>
              {formData.firstName} {formData.lastName}
            </p>
            <p style={{ textAlign: "left", marginTop: "-10px" }}>
              คุณเกิด วัน{dayOfWeek && dayNames[dayOfWeek]}ที่ {formData.day}{" "}
              {monthNames[parseInt(formData.month)]} {formData.year}
            </p>
            <p style={{ textAlign: "left", marginTop: "-10px" }}>
              ราศี: {zodiac}
            </p>
          </div>
        </div>
        <br />
        <button
          onClick={() => setShowDetails(!showDetails)}
          className="read-more-button"
        >
          {showDetails ? "ซ่อนข้อมูล" : "อ่านข้อมูลเพิ่มเติม"}
        </button>
        <div className="form-groups">
          {showDetails && dayOfWeek && (
            <div
              style={{
                background: "#fafafa6b ",
                padding: "10px",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              <h3 style={{ textAlign: "center" }}>รายละเอียด</h3>
              <p></p>
              <h6 style={{ textAlign: "center" }}>
                “โอม ศรี มหาลักษมี เจ นะมะหะ”
              </h6>
              <p></p>
              <p>ความหมาย Wallpaper ประจำตัวคุณคือ</p>
              <p
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{
                  __html: flowerDescriptions[dayOfWeek],
                }}
              />

              <p
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{
                  __html: diamondDescriptions[dayOfWeek],
                }}
              />
              <p
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{
                  __html: prayerDescriptions[formData.prayer],
                }}
              />
            </div>
          )}
        </div>

        {/* ปุ่มกดต่อไป */}
        <div className="d-flex justify-content-between mt-4">
          {/* ปุ่มกลับ */}
          <img
            src="/images/bt_back1.png"
            alt="กลับ"
            style={{ cursor: "pointer", width: "65px", height: "auto" }}
            onClick={prevStep}
          />

          {/* ปุ่มต่อไป */}
          <img
            src="/images/bt_next1.png"
            alt="ต่อไป"
            style={{ cursor: "pointer", width: "65px", height: "auto" }}
            onClick={handleNextStep}
          />
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "80vh",
              maxWidth: "80vw",
            },
          }}
        >
          <img
            src={watermarkedImageState}
            alt="Generated"
            style={{ width: "100%", height: "auto" }}
          />

          <button
            className="btn btn-danger"
            onClick={() => setIsModalOpen(false)}
            style={{ marginTop: "10px" }}
          >
            ปิด
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default ResultPage;
