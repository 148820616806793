import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./FortunePage.css";

const FortunePage: React.FC = () => {
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [selectedAstrologer, setSelectedAstrologer] = useState<any | null>(
    null
  );

  const astrologers = [
    {
      id: 1,
      name: "อ.แม่มาลา",
      image: "/images/fortune/person/fortune-p1.png",
      strong: "ผู้เชี่ยวชาญศาสตร์การดูไพ่ทาโรต์และไพ่จิตวิทยา",
      description: "สามารถดูไพ่ได้ตั้งแต่พื้นดวงแบบจักรราศี หรือเจาะคำถามและสามารถดูฮวงจุ้ยจากไพ่ด้วยเช่นกัน มักได้รับคำเชิญออกตามงานอีเว้นท์ต่างๆหรือเปิดไพ่เฉพาะกลุ่ม vip",
    },
    {
        id: 2,
        name: "อ.แม่มาลา",
        image: "/images/fortune/person/fortune-p1.png",
        strong: "ผู้เชี่ยวชาญศาสตร์การดูไพ่ทาโรต์และไพ่จิตวิทยา",
        description: "สามารถดูไพ่ได้ตั้งแต่พื้นดวงแบบจักรราศี หรือเจาะคำถามและสามารถดูฮวงจุ้ยจากไพ่ด้วยเช่นกัน มักได้รับคำเชิญออกตามงานอีเว้นท์ต่างๆหรือเปิดไพ่เฉพาะกลุ่ม vip",
      },
      {
        id: 3,
        name: "อ.แม่มาลา",
        image: "/images/fortune/person/fortune-p1.png",
        strong: "ผู้เชี่ยวชาญศาสตร์การดูไพ่ทาโรต์และไพ่จิตวิทยา",
        description: "สามารถดูไพ่ได้ตั้งแต่พื้นดวงแบบจักรราศี หรือเจาะคำถามและสามารถดูฮวงจุ้ยจากไพ่ด้วยเช่นกัน มักได้รับคำเชิญออกตามงานอีเว้นท์ต่างๆหรือเปิดไพ่เฉพาะกลุ่ม vip",
      },
      {
        id: 4,
        name: "อ.แม่มาลา",
        image: "/images/fortune/person/fortune-p1.png",
        strong: "ผู้เชี่ยวชาญศาสตร์การดูไพ่ทาโรต์และไพ่จิตวิทยา",
        description: "สามารถดูไพ่ได้ตั้งแต่พื้นดวงแบบจักรราศี หรือเจาะคำถามและสามารถดูฮวงจุ้ยจากไพ่ด้วยเช่นกัน มักได้รับคำเชิญออกตามงานอีเว้นท์ต่างๆหรือเปิดไพ่เฉพาะกลุ่ม vip",
      },
      {
        id: 5,
        name: "อ.แม่มาลา",
        image: "/images/fortune/person/fortune-p1.png",
        strong: "ผู้เชี่ยวชาญศาสตร์การดูไพ่ทาโรต์และไพ่จิตวิทยา",
        description: "สามารถดูไพ่ได้ตั้งแต่พื้นดวงแบบจักรราศี หรือเจาะคำถามและสามารถดูฮวงจุ้ยจากไพ่ด้วยเช่นกัน มักได้รับคำเชิญออกตามงานอีเว้นท์ต่างๆหรือเปิดไพ่เฉพาะกลุ่ม vip",
      },
      {
        id: 6,
        name: "อ.แม่มาลา",
        image: "/images/fortune/person/fortune-p1.png",
        strong: "ผู้เชี่ยวชาญศาสตร์การดูไพ่ทาโรต์และไพ่จิตวิทยา",
        description: "สามารถดูไพ่ได้ตั้งแต่พื้นดวงแบบจักรราศี หรือเจาะคำถามและสามารถดูฮวงจุ้ยจากไพ่ด้วยเช่นกัน มักได้รับคำเชิญออกตามงานอีเว้นท์ต่างๆหรือเปิดไพ่เฉพาะกลุ่ม vip",
      },
   
    // เพิ่มข้อมูลอื่นๆ
  ];

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas is not available");
      return;
    }

    const context = canvas.getContext("2d");
    if (!context) {
      console.error("Canvas context is not available");
      return;
    }

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particlesArray: Particle[] = [];
    const numParticles = 5;

    class Particle {
      x: number;
      y: number;
      speedX: number;
      speedY: number;
      size: number;
      opacity: number;

      constructor() {
        this.x = Math.random() * (canvas?.width ?? 0);
        this.y = Math.random() * (canvas?.height ?? 0);
        this.speedX = -Math.random() * 3 - 1;
        this.speedY = Math.random() * 3 + 1;
        this.size = Math.random() * 3 + 1;
        this.opacity = Math.random();
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.x < 0 || this.y > (canvas?.height ?? 0)) {
          this.x = Math.random() * (canvas?.width ?? 0);
          this.y = 0;
          this.speedX = -Math.random() * 3 - 1;
          this.speedY = Math.random() * 3 + 1;
        }
      }

      draw() {
        if (!context) return;
        context.beginPath();
        context.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        context.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
        context.fill();
      }
    }

    for (let i = 0; i < numParticles; i++) {
      particlesArray.push(new Particle());
    }

    const animate = () => {
      if (!context) return;
      context.clearRect(0, 0, canvas?.width ?? 0, canvas?.height ?? 0);

      particlesArray.forEach((particle) => {
        particle.update();
        particle.draw();
      });

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (context) {
        context.clearRect(0, 0, canvas?.width ?? 0, canvas?.height ?? 0);
      }
    };
  }, []);

  return (
    <div className="container container-f text-center py-3">
      <canvas ref={canvasRef} className="particle-canvas" />

      <AnimatePresence>
        {selectedAstrologer ? (
          <motion.div
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedAstrologer(null)}
          >
            <motion.div
              className="modal-content"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="close-button-f"
                onClick={() => setSelectedAstrologer(null)}
              >
                
              </div>
              <div className="d-flex justify-content-center m-5">
              <div className="astrologer-card-s">
                <img
                  src={selectedAstrologer.image}
                  alt={selectedAstrologer.name}
                  className="card-img-top-s"
                />
                <div className="card-body-s p-2">
                  <div className="name-banner-s">
                    <p className="mb-0 astrologer-name-s">
                      {selectedAstrologer.name}
                    </p>
                  </div>
                </div>
                </div>
              </div>
              <h2 className="p-name">{selectedAstrologer.name}</h2>
              <p className="p-des">{selectedAstrologer.strong}</p>
              <p className="p-des">{selectedAstrologer.description}</p>
              <button className="btn btn-primary-f">นัดหมายดูดวง</button>
            </motion.div>
          </motion.div>
        ) : (
          <div>
            <h1 className="title-f">เลือกหมอดู</h1>
            <div className="row">
              {astrologers.map((astrologer,index) => (
                <div
                  className="col-6 mb-4"
                  key={astrologer.id}
                  onClick={() => setSelectedAstrologer(astrologer)}
                >
                  <div className="astrologer-card">
                    <motion.div
                     initial={{ opacity: 0, y: 50 }}
                     animate={{ opacity: 1, y: 0 }}
                     transition={{ delay: index * 0.02, duration: 0.05 }}
                    >
                    <img 
                     
                      src={astrologer.image}
                      alt={astrologer.name}
                      className="card-img-top"
                    />
                    <div className="card-body p-2">
                      <div className="name-banner">
                        <p className="mb-0 astrologer-name">
                          {astrologer.name}
                        </p>
                      </div>
                    </div>
                    </motion.div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FortunePage;
