// src/App.tsx
import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { tarotDeck, TarotCard } from "./tarotDeck";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Taros.css";

const TarosPage: React.FC<{
  formData: any;
  updateFormData: (data: any) => void;
}> = ({formData, updateFormData}) => {
  const navigate = useNavigate(); // Initialize navigate function
  const [shuffledDeck, setShuffledDeck] = useState<TarotCard[]>([]);
  const [selectedCard, setSelectedCard] = useState<TarotCard | null>(null);
  const [readCard, setReadCard] = useState(false);
  const [previewPayment, setPreviewPayment] = useState(false);
  const [readmore, setReadmore] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const shuffleDeck = () => {
    setReadCard(false);
    setIsResetting(true); // เริ่มต้นลบการ์ดเก่า
    setTimeout(() => {
      const shuffled = [...tarotDeck].sort(() => Math.random() - 0.5);
      setShuffledDeck(shuffled);
      setSelectedCard(null);
      setIsResetting(false); // แสดงการ์ดใหม่
    }, 300); // หน่วงเวลา 300ms สำหรับการลบการ์ด
  };

  const handleBackToHome = () => {
    navigate("/tarot/home"); // Navigate to the desired route
  };
  const handleToPaymentPage = async () => {
    if (!selectedCard) {
      console.error("No card selected");
      return;
    }
  
    try {
      const response = await fetch(selectedCard.image);
      const blob = await response.blob();
  
      const file = new File([blob], "selected_card.jpg", { type: blob.type });
  
      updateFormData({ image: file, itemId: selectedCard.itemId});
  
      navigate("/tarot/payment");
    } catch (error) {
      console.error("Error fetching the image:", error);
    }
  };
  
  

  const handleReCard = () => {
    window.location.reload();
  };

  useEffect(() => {
    console.log(formData.userId);
    shuffleDeck();

    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas is not available");
      return;
    }

    const context = canvas.getContext("2d");
    if (!context) {
      console.error("Canvas context is not available");
      return;
    }

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particlesArray: Particle[] = [];
    const numParticles = 5;

    class Particle {
      x: number;
      y: number;
      speedX: number;
      speedY: number;
      size: number;
      opacity: number;

      constructor() {
        // Use the `canvas!` operator to assert that `canvas` is not null
        this.x = Math.random() * (canvas?.width ?? 0);
        this.y = Math.random() * (canvas?.height ?? 0);
        this.speedX = -Math.random() * 3 - 1;
        this.speedY = Math.random() * 3 + 1;
        this.size = Math.random() * 3 + 1;
        this.opacity = Math.random();
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.x < 0 || this.y > (canvas?.height ?? 0)) {
          this.x = Math.random() * (canvas?.width ?? 0);
          this.y = 0;
          this.speedX = -Math.random() * 3 - 1;
          this.speedY = Math.random() * 3 + 1;
        }
      }

      draw() {
        if (!context) return; // Double-check context is available
        context.beginPath();
        context.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        context.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
        context.fill();
      }
    }

    for (let i = 0; i < numParticles; i++) {
      particlesArray.push(new Particle());
    }

    const animate = () => {
      if (!context) return; // Ensure context is valid
      context.clearRect(0, 0, canvas?.width ?? 0, canvas?.height ?? 0);

      particlesArray.forEach((particle) => {
        particle.update();
        particle.draw();
      });

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      // Cleanup function
      if (context) {
        context.clearRect(0, 0, canvas?.width ?? 0, canvas?.height ?? 0);
      }
    };
  }, []);

  const handlePredict = () => {
    setReadCard(true);
  };

  const handlePaymentPreviewOpen = () => {
    setPreviewPayment(true);
  };

  const handleReadmore = (read: boolean) => {
    setReadmore(read);
  };

  return (
    <div className="container-taros">
      {/* Canvas for Particle Effect */}
      <canvas ref={canvasRef} className="particle-canvas" />
      {/* Header */}
      {!selectedCard && !isResetting ? (
        <AnimatePresence>
          <header className="header">
            <motion.h1
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1.05, duration: 0.1 }}
            >
              ตั้งสมาธิและกดเลือกไพ่ 1 ใบ
            </motion.h1>
          </header>
        </AnimatePresence>
      ) : (
        <AnimatePresence>
          {!readCard ? (
            <header className="header">
              <motion.h1
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.05, duration: 0.1 }}
              ></motion.h1>
            </header>
          ) : (
            <div className="topshowreal"></div>
          )}
        </AnimatePresence>
      )}

      {!readCard ? (
        <div>
          {/* Card Section */}
          <main className="deck-container">
            <AnimatePresence>
              {!selectedCard && !isResetting && (
                <motion.div
                  className="deck-grid"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {shuffledDeck.map((card, index) => (
                    <motion.div
                      key={card.id}
                      className="card"
                      onClick={() => setSelectedCard(card)} // เลือกการ์ด
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.02, duration: 0.05 }}
                    >
                      <div className="card-back"></div>
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </main>

          {/* Footer */}
          {!selectedCard && !isResetting && (
            <div className="footers">
              <div className="button-containers">
                <motion.button
                  onClick={handleReCard}
                  className="shuffle-button"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1.55, duration: 0.4 }}
                >
                  สับไพ่
                </motion.button>
              </div>
            </div>
          )}

          {/* แสดง Selected Card */}
          <AnimatePresence>
            {selectedCard && !isResetting && (
              <motion.div
                className="selected-confirm"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <motion.div
                  className="card-confirm"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.2 }}
                ></motion.div>
                {/*<h3>คุณเลือก: {selectedCard.name}</h3>*/}
                <p>
                  คุณได้เลือกไพ่แล้ว <br />
                  ตั้งจิตและกดทำนายเพื่อเปิดไพ่
                </p>
                {/* Footer */}
                <div className="footers">
                  <div className="button-container">
                    <motion.button
                      onClick={shuffleDeck}
                      className="shuffle-button"
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5, duration: 0.1 }}
                    >
                      เลือกใหม่
                    </motion.button>
                    <motion.button
                      onClick={selectedCard ? handlePredict : shuffleDeck}
                      className="shuffle-button"
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5, duration: 0.1 }}
                    >
                      ทำนาย
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <div>
          {" "}
          {/* แสดง Selected Card */}
          {!previewPayment ? (
            <AnimatePresence>
              {selectedCard && !isResetting && (
                <motion.div
                  className="selected-confirm"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                >
                  <motion.img
                    src={selectedCard.image} // Replace with your image path
                    alt="Card Image"
                    className="card-front"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1, duration: 0.2 }}
                  />

                  <div className="boxDes">
                    <h6>{selectedCard.name}</h6>
                    <p>{selectedCard.description}</p>
                  </div>
                  <motion.i
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5, duration: 0.2 }}
                     >ศิลปิน ผู้ออกแบบไพ่ชุด ART OF DECK "{selectedCard.artist}"</motion.i>
                  <div>
                    <motion.button
                      onClick={handlePaymentPreviewOpen}
                      className="buyproduct-button"
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.05, duration: 0.4 }}
                    >
                      ซื้อไพ่ทาโรต์เพื่อส่งเสริมดวง
                    </motion.button>

                    <motion.button
                      onClick={handleBackToHome}
                      className="repage-button"
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.1, duration: 0.4 }}
                    >
                      ทำนายอีกครั้ง
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          ) : (
            <AnimatePresence>
              {selectedCard && !isResetting && (
                <motion.div
                  className="selected-confirm"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                >
                  {!readmore ? (
                    <div>
                      <motion.img
                        src={selectedCard.image} // Replace with your image path
                        alt="Card Image"
                        className="card-front-2"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1, duration: 0.2 }}
                      />
                      <motion.p
                        className="clickable-text"
                        onClick={() => handleReadmore(true)}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1, duration: 0.2 }}
                      >
                        เปิดคำทำนาย
                      </motion.p>
                      <motion.i
                       initial={{ opacity: 0, y: 50 }}
                       animate={{ opacity: 1, y: 0 }}
                       transition={{ delay: 0.5, duration: 0.2 }}
                      >ศิลปิน ผู้ออกแบบไพ่ชุด ART OF DECK "{selectedCard.artist}"</motion.i>
                    </div>
                  ) : (
                    <div>
                      <motion.img
                        src={selectedCard.image} // Replace with your image path
                        alt="Card Image"
                        className="card-front-3"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1, duration: 0.2 }}
                      />
                      <motion.p
                        className="clickable-text"
                        onClick={() => handleReadmore(false)}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1, duration: 0.2 }}
                      >
                        ปิดคำทำนาย
                      </motion.p>
                      
                      <motion.div className="boxDes">
                        <h6>{selectedCard.name}</h6>
                        <p>{selectedCard.description}</p>
                      </motion.div>
                     <motion.i
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5, duration: 0.2 }}
                     >ศิลปิน ผู้ออกแบบไพ่ชุด ART OF DECK "{selectedCard.artist}"</motion.i>
                    </div>
                  )}

                  <div>
                    <motion.button
                      onClick={handleToPaymentPage}
                      className="buyproduct-button"
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.05, duration: 0.4 }}
                    >
                      ชำระเงิน
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </div>
      )}
    </div>
  );
};

export default TarosPage;
