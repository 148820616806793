import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";

const BackEndManager: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mode, setMode] = useState<
    "Users" | "Transfer" | "ManagerPaymentCurrent" | "EditUser"
  >("Users");

  const [adminId, setAdminId] = useState("");
  const [adminPass, setAdminPass] = useState("");
  const [referralData, setReferralData] = useState<any[]>([]);
  const [paymentRounds, setPaymentRounds] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [verifyFilter, setVerifyFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);

  const [filteredRounds, setFilteredRounds] = useState<any[]>([]);
  const [searchId, setSearchId] = useState("");

  // State สำหรับ Payment Current
  const [paymentCurrent, setPaymentCurrent] = useState<number | null>(null);

  // Filter states
  const [filterDon, setFilterDon] = useState<string>("all");
  const [filterYear, setFilterYear] = useState<string>("all");
  const [filterMonth, setFilterMonth] = useState<string>("all");
  const [filterDay, setFilterDay] = useState<string>("all");
  const [filterRound, setFilterRound] = useState<string>("all");

  const [filterMonthTransfer, setFilterMonthTransfer] = useState<string | null>(
    null
  );

  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [newImage, setNewImage] = useState<File | null>(null); // สำหรับเก็บไฟล์รูปภาพใหม่
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null); // URL รูปภาพ preview
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  // ตรวจสอบการล็อกอิน
  const handleLogin = () => {
    if (adminId === "artventureNFTAdmin" && adminPass === "ArtVen951") {
      setIsLoggedIn(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: "Invalid ID or Password",
      });
    }
  };

  // ดึงข้อมูลจาก API
  useEffect(() => {
    if (isLoggedIn) {
      fetchReferralData();
    }
  }, [isLoggedIn]);

  const fetchReferralData = () => {
    fetch("https://artelu-api.vercel.app/api/admin/backEndManager")
      .then((response) => response.json())
      .then((data) => {
        setReferralData(data);
        setFilteredData(data);
      })
      .catch((error) => {
        console.error("Error fetching referral data:", error);
      });
  };

  const fetchPaymentRounds = () => {
    fetch(
      "https://artelu-api.vercel.app/api/admin/backEndManager/getPaymentCurrent"
    )
      .then((response) => response.json())
      .then((data) => {
        Swal.close(); // หยุดการหมุนเมื่อข้อมูลถูกโหลด
        setPaymentRounds(data); // Assuming API returns an array of rounds
        setFilteredRounds(data); // Initially, show all rounds
      })
      .catch((error) => {
        Swal.fire("Error", "Failed to fetch payment rounds data.", "error");
      });
  };

  const handleManagePaymentRound = (month: number, round: number) => {
    setMode("Transfer");

    // กรองรายการตาม month และ payment_round ที่ตรงกัน
    const filtered = referralData.filter(
      (item) =>
        item.income_current &&
        Object.values(item.income_current).some(
          (income: any) =>
            income.month === month && income.payment_round === round
        )
    );
    setFilteredData(filtered);
  };

  // ฟังก์ชั่นการค้นหาและกรอง
  const handleSearch = () => {
    let filtered = referralData;

    if (verifyFilter.length) {
      filtered = filtered.filter((item) =>
        verifyFilter.includes(item.verify ? "approved" : "pending")
      );
    }

    if (statusFilter.length) {
      filtered = filtered.filter((item) =>
        statusFilter.includes(item.status ? "active" : "inactive")
      );
    }

    if (searchTerm) {
      filtered = filtered.filter((item) =>
        item["refferal-code"].includes(searchTerm)
      );
    }

    setFilteredData(filtered);
  };

  const handleReset = () => {
    setSearchId("");
    setFilterDon("all");
    setFilterYear("all");
    setFilterMonth("all");
    setFilterDay("all");
    setFilterRound("all");
    setFilteredRounds(paymentRounds); // แสดงรายการทั้งหมดหลังจากรีเซ็ต
  };

  // Filtering function
  const handleFilter = () => {
    let filtered = paymentRounds;

    if (searchId) {
      filtered = filtered.filter((item) =>
        item["base-payment-rounds-current"].toString().includes(searchId)
      );
    }

    if (filterDon !== "all") {
      filtered = filtered.filter((item) =>
        filterDon === "true" ? item.done === true : item.done === false
      );
    }

    if (filterYear !== "all") {
      filtered = filtered.filter(
        (item) => item.transfer_year.toString() === filterYear
      );
    }

    if (filterMonth !== "all") {
      filtered = filtered.filter(
        (item) => item.transfer_month.toString() === filterMonth
      );
    }

    if (filterDay !== "all") {
      filtered = filtered.filter(
        (item) => item.transfer_day.toString() === filterDay
      );
    }

    if (filterRound !== "all") {
      filtered = filtered.filter(
        (item) => item.round.toString() === filterRound
      );
    }

    setFilteredRounds(filtered);
  };

  // ฟังก์ชั่นสลับโหมด
  const switchMode = (
    newMode: "Users" | "Transfer" | "ManagerPaymentCurrent" | "EditUser"
  ) => {
    setMode(newMode);
  };

  // ฟังก์ชั่นสำหรับดึงค่า Payment Current
  const fetchPaymentCurrent = () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(
      "https://artelu-api.vercel.app/api/admin/backEndManager/getPaymentCurrent"
    )
      .then((response) => response.json())
      .then((data) => {
        Swal.close();
        setPaymentCurrent(data["base-payment-rounds-current"]);
      })
      .catch((error) => {
        Swal.fire("Error", "Failed to fetch payment rounds current.", "error");
      });
  };

  // ฟังก์ชั่นสำหรับอัปเดตค่า Payment Current
  /* const updatePaymentCurrent = () => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Update",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Updating...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        fetch(
          "https://artelu-api.vercel.app/api/admin/backEndManager/updatePaymentCurrent",
          {
            method: "POST",
          }
        )
          .then((response) => response.json())
          .then((data) => {
            Swal.fire("Success", "Payment round updated!", "success");
            fetchPaymentCurrent(); // ดึงข้อมูลล่าสุดหลังจากอัปเดต
          })
          .catch((error) => {
            Swal.fire("Error", "Failed to update payment round.", "error");
          });
      }
    });
  };*/

  const handleEditUser = (userData: any) => {
    setSelectedUser(userData); // เก็บข้อมูลผู้ใช้ที่เลือก
    setMode("EditUser"); // เปลี่ยนโหมดเป็น EditUser
  };

  // ฟังก์ชันสำหรับแสดง preview ของภาพที่อัปโหลดใหม่
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      // ตรวจสอบประเภทของไฟล์ (ต้องเป็น .png หรือ .jpg เท่านั้น)
      const validTypes = ["image/png", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        Swal.fire("Error", "กรุณาเลือกไฟล์ภาพประเภท .png หรือ .jpg", "error");
        return;
      }

      // ตรวจสอบขนาดของไฟล์ (ไม่เกิน 3MB)
      const maxSize = 3 * 1024 * 1024; // 3MB
      if (file.size > maxSize) {
        Swal.fire("Error", "ขนาดไฟล์ต้องไม่เกิน 3MB", "error");
        return;
      }

      // เก็บไฟล์รูปภาพใหม่
      setNewImage(file);
      setSelectedImage(file);

      // สร้าง URL preview สำหรับรูปภาพใหม่
      const imageUrl = URL.createObjectURL(file);
      setPreviewImageUrl(imageUrl); // ตั้งค่า preview
    }
  };

 // ฟังก์ชันอัปเดตข้อมูลผู้ใช้
const handleUpdateUser = (updatedUser: any) => {
  const formData = new FormData();
  formData.append("refferalCode", updatedUser["refferal-code"]);
  formData.append("status", updatedUser.status.toString());
  formData.append("verify", updatedUser.verify.toString());
  formData.append("user", JSON.stringify(updatedUser.user)); // ส่งข้อมูล user เป็นออบเจ็กต์
  formData.append("bank", JSON.stringify(updatedUser.bank)); // ส่งข้อมูล bank เป็นออบเจ็กต์

  // ถ้ามีการอัปโหลดไฟล์ใหม่ ให้อัปเดทไฟล์ด้วย
  if (selectedImage) {
    formData.append("image", selectedImage);
  }

  // แสดง loading Swal
  Swal.fire({
    title: "กำลังอัปเดตข้อมูล...",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  fetch("https://artelu-api.vercel.app/api/admin/backEndManager/updateUser", {
    method: "POST",
    body: formData, // ใช้ FormData เพื่อรองรับการอัพโหลดไฟล์
  })
    .then((response) => response.json())
    .then((data) => {
      Swal.close(); // ปิด Swal loading
      Swal.fire("Success", "แก้ไขข้อมูลสำเร็จ!", "success");
      setMode("Users");
      fetchReferralData();
    })
    .catch((error) => {
      Swal.close(); // ปิด Swal loading
      Swal.fire("Error", "เกิดข้อผิดพลาดในการแก้ไข", "error");
    });
};


  // ฟังก์ชั่นแสดงข้อมูลใน income_current
  const renderIncomeCurrent = (income_current: any) => {
    if (!income_current) return "ไม่มีข้อมูล";
    const incomeKeys = Object.keys(income_current);
    return incomeKeys.map((key) => (
      <div key={key} className="mb-3">
        <div>
          <strong>ID:</strong> {key}
        </div>
        <div>
          <strong>Status:</strong> {income_current[key].status}
        </div>
        <div>
          <strong>Total Amount:</strong> {income_current[key].total_amount}
        </div>
        <div>
          <strong>Total Money:</strong> {income_current[key].total_money}
        </div>
        <div>
          <strong>Month:</strong> {income_current[key].month}
        </div>
        <div>
          <strong>Payment Round:</strong> {income_current[key].payment_round}
        </div>
        <div>
          <strong>Transfer Approve:</strong>{" "}
          <span
            className={`badge ${
              income_current[key].transfer_approve ? "bg-success" : "bg-warning"
            }`}
          >
            {income_current[key].transfer_approve ? "โอนแล้ว" : "รอรอบโอน"}
          </span>
        </div>
      </div>
    ));
  };

  // ฟังก์ชั่นแสดงข้อมูลใน income_old
  const renderIncomeOldPopup = (income_old: any[]) => {
    if (!income_old) return "ไม่มีข้อมูล";

    const incomeOldHtml = income_old
      .map((income, index) => {
        const incomeKeys = Object.keys(income);
        return incomeKeys
          .map((key) => {
            return `
            <div class="mb-3">
              <div><strong>ID:</strong> ${key}</div>
              <div><strong>Status:</strong> ${income[key].status}</div>
              <div><strong>Total Amount:</strong> ${
                income[key].total_amount
              }</div>
              <div><strong>Total Money:</strong> ${
                income[key].total_money
              }</div>
              <div><strong>Month:</strong> ${income[key].month}</div>
              <div><strong>Payment Round:</strong> ${
                income[key].payment_round
              }</div>
              <div><strong>Transfer Approve:</strong> 
                <span class="badge ${
                  income[key].transfer_approve ? "bg-success" : "bg-warning"
                }">
                  ${income[key].transfer_approve ? "โอนแล้ว" : "รอรอบโอน"}
                </span>
              </div>
            </div>
          `;
          })
          .join("");
      })
      .join("");

    return `
    <div>
      ${incomeOldHtml}
    </div>
  `;
  };

  return (
    <div className="container mt-5 mb-5">
      {!isLoggedIn ? (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="cards p-4">
              <h2 className="text-center mb-4">Admin Login</h2>
              <div className="mb-3">
                <label>ID:</label>
                <input
                  type="text"
                  className="form-control"
                  value={adminId}
                  onChange={(e) => setAdminId(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>Password:</label>
                <input
                  type="password"
                  className="form-control"
                  value={adminPass}
                  onChange={(e) => setAdminPass(e.target.value)}
                />
              </div>
              <button onClick={handleLogin} className="btn btn-primary w-100">
                Login
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-4">
            <button
              className={`btn ${
                mode === "Users" ? "btn-primary" : "btn-secondary"
              } me-2`}
              onClick={() => switchMode("Users")}
            >
              Users
            </button>
            <button
              className={`btn ${
                mode === "ManagerPaymentCurrent"
                  ? "btn-primary"
                  : "btn-secondary"
              }`}
              onClick={() => {
                switchMode("ManagerPaymentCurrent");

                // เรียกฟังก์ชันเพื่อดึงข้อมูลเมื่อสลับโหมด
                Swal.fire({
                  title: "Loading...",
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
                handleReset();
                fetchPaymentRounds(); // ดึงข้อมูล payment round เมื่อเข้าสู่โหมดนี้
              }}
            >
              Manager Payment Current
            </button>
          </div>
          {/* ตัวคัดกรอง */}
          {mode === "Users" && (
            <div className="mb-3 d-flex align-items-center">
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="approved"
                  value="approved"
                  checked={verifyFilter.includes("approved")}
                  onChange={(e) => {
                    const newFilter = [...verifyFilter];
                    if (e.target.checked) {
                      newFilter.push("approved");
                    } else {
                      const index = newFilter.indexOf("approved");
                      newFilter.splice(index, 1);
                    }
                    setVerifyFilter(newFilter);
                  }}
                />
                <label className="form-check-label" htmlFor="approved">
                  อนุมัติ
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="pending"
                  value="pending"
                  checked={verifyFilter.includes("pending")}
                  onChange={(e) => {
                    const newFilter = [...verifyFilter];
                    if (e.target.checked) {
                      newFilter.push("pending");
                    } else {
                      const index = newFilter.indexOf("pending");
                      newFilter.splice(index, 1);
                    }
                    setVerifyFilter(newFilter);
                  }}
                />
                <label className="form-check-label" htmlFor="pending">
                  ตรวจสอบ
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="active"
                  value="active"
                  checked={statusFilter.includes("active")}
                  onChange={(e) => {
                    const newFilter = [...statusFilter];
                    if (e.target.checked) {
                      newFilter.push("active");
                    } else {
                      const index = newFilter.indexOf("active");
                      newFilter.splice(index, 1);
                    }
                    setStatusFilter(newFilter);
                  }}
                />
                <label className="form-check-label" htmlFor="active">
                  เปิด
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inactive"
                  value="inactive"
                  checked={statusFilter.includes("inactive")}
                  onChange={(e) => {
                    const newFilter = [...statusFilter];
                    if (e.target.checked) {
                      newFilter.push("inactive");
                    } else {
                      const index = newFilter.indexOf("inactive");
                      newFilter.splice(index, 1);
                    }
                    setStatusFilter(newFilter);
                  }}
                />
                <label className="form-check-label" htmlFor="inactive">
                  ปิด
                </label>
              </div>
            </div>
          )}

          {/* โหมด Users */}
          {mode === "Users" && (
            <>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="ค้นหาโดย Referral Code, Name หรือ Phone Number"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="btn btn-secondary" onClick={handleSearch}>
                  ค้นหา
                </button>
              </div>

              {/* แสดงรายการ Referral */}
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Referral Code</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Payments</th>
                    <th>Verify</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={item["refferal-code"]}>
                      <td>{item["refferal-code"]}</td>
                      <td>{item.user.name}</td>
                      <td>{item.user.phoneNumber}</td>
                      <td>
                        <button
                          className="btn btn-info"
                          onClick={() => {
                            Swal.fire({
                              title: "Income Old",
                              html: renderIncomeOldPopup(item.income_old),
                              showCloseButton: true,
                              width: "600px",
                            });
                          }}
                        >
                          ดูข้อมูล
                        </button>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            item.verify ? "bg-success" : "bg-danger"
                          }`}
                        >
                          {item.verify ? "อนุมัติ" : "ตรวจสอบ"}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            item.status ? "bg-success" : "bg-danger"
                          }`}
                        >
                          {item.status ? "ใช้งาน" : "ปิด"}
                        </span>
                      </td>
                      <td>
                        {!item.verify && (
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              Swal.fire({
                                title: "ยืนยันการอนุมัติ",
                                showCancelButton: true,
                                confirmButtonText: "Confirm",
                                cancelButtonText: "Cancel",
                                preConfirm: () => {
                                  // แสดงการหมุนขณะรอการทำงานของ API
                                  Swal.showLoading();

                                  return fetch(
                                    "https://artelu-api.vercel.app/api/admin/backEndManager/approve",
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        refferalCode: item["refferal-code"], // ส่ง refferalCode ไปใน API
                                        userId: item["userId"],
                                      }),
                                    }
                                  )
                                    .then((response) => response.json())
                                    .then((data) => {
                                      Swal.fire(
                                        "Success",
                                        "อนุมัติสำเร็จ!",
                                        "success"
                                      ).then(() => {
                                        // Refresh หน้าเพจเพื่อให้ข้อมูลเป็นปัจจุบัน
                                        fetchReferralData();
                                      });
                                    })
                                    .catch((error) => {
                                      Swal.fire(
                                        "Error",
                                        "เกิดข้อผิดพลาดในการอนุมัติ",
                                        "error"
                                      );
                                    });
                                },
                              });
                            }}
                          >
                            อนุมัติ
                          </button>
                        )}

                        <button
                          className="btn btn-warning"
                          onClick={() => handleEditUser(item)}
                        >
                          ตรวจสอบแก้ไข
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {mode === "EditUser" && selectedUser && (
            <div className="container mt-5">
              <div className="mb-5">
                <button
                  className="btn btn-secondary"
                  onClick={() => setMode("Users")}
                >
                  Back to Users
                </button>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3>แก้ไขข้อมูลผู้ใช้ {selectedUser.referral}</h3>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label>ชื่อ</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.user.name}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              user: {
                                ...selectedUser.user,
                                name: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label>นามสกุล</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.user.lastname}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              user: {
                                ...selectedUser.user,
                                lastname: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label>เบอร์โทรศัพท์</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.user.phoneNumber}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              user: {
                                ...selectedUser.user,
                                phoneNumber: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label>ที่อยู่</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.user.address}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              user: {
                                ...selectedUser.user,
                                address: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label>จังหวัด</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.user.province}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              user: {
                                ...selectedUser.user,
                                province: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <label>อำเภอ</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.user.district}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              user: {
                                ...selectedUser.user,
                                district: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <label>รหัสไปรษณีย์</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.user.postalCode}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              user: {
                                ...selectedUser.user,
                                postalCode: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    {/* ข้อมูลธนาคาร */}
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label>ชื่อธนาคาร</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.bank?.bankName || ""}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              bank: {
                                ...selectedUser.bank,
                                bankName: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label>หมายเลขบัญชีธนาคาร</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser.bank?.bankAccount || ""}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              bank: {
                                ...selectedUser.bank,
                                bankAccount: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    {/* ถ้าไม่มี preview ของภาพใหม่ ให้แสดงรูปภาพปัจจุบัน */}
                    {!previewImageUrl && selectedUser.user.image_url && (
                      <div className="mb-3">
                        <label>รูปภาพปัจจุบัน:</label>
                        <div>
                          <img
                            src={selectedUser.user.image_url}
                            alt="Current user"
                            width="200"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              Swal.fire({
                                title: "รูปภาพปัจจุบัน",
                                imageUrl: selectedUser.user.image_url,
                                imageWidth: 400,
                                imageAlt: "Current user",
                                showCloseButton: true,
                                showConfirmButton: false,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {/* แสดง preview ของภาพใหม่ หากมีการอัปโหลด */}
                    {previewImageUrl && (
                      <div className="mb-3">
                        <label>Preview รูปภาพที่อัปโหลด:</label>
                        <div>
                          <img
                            src={previewImageUrl}
                            alt="Preview"
                            width="200"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              Swal.fire({
                                title: "Preview รูปภาพที่อัปโหลด",
                                imageUrl: previewImageUrl,
                                imageWidth: 400,
                                imageAlt: "Preview",
                                showCloseButton: true,
                                showConfirmButton: false,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {/* อัปโหลดไฟล์ใหม่ */}
                    <div className="mb-3">
                      <label>อัพโหลดไฟล์ใหม่:</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleImageUpload}
                      />
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={selectedUser.status}
                            onChange={(e) =>
                              setSelectedUser({
                                ...selectedUser,
                                status: e.target.checked,
                              })
                            }
                          />
                          <label className="form-check-label">
                            สถานะ (ใช้งาน)
                          </label>
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handleUpdateUser(selectedUser)}
                    >
                      บันทึกการเปลี่ยนแปลง
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* โหมด Manager Payment Current */}
          {/* ManagerPaymentCurrent mode */}
          {mode === "ManagerPaymentCurrent" && (
            <>


              {/* Filter Dropdowns */}
              <div className="mb-3 d-flex align-items-center">
                <select
                  className="form-select me-2"
                  value={filterDon}
                  onChange={(e) => setFilterDon(e.target.value)}
                >
                  <option value="all">ทั้งหมด</option>
                  <option value="true">เสร็จสิ้น</option>
                  <option value="false">ยังไม่เสร็จ</option>
                </select>

                <select
                  className="form-select me-2"
                  value={filterYear}
                  onChange={(e) => setFilterYear(e.target.value)}
                >
                  <option value="all">รอบปีทั้งหมด</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  {/* Add more years as needed */}
                </select>

                <select
                  className="form-select me-2"
                  value={filterMonth}
                  onChange={(e) => setFilterMonth(e.target.value)}
                >
                  <option value="all">รอบเดือนทั้งหมด</option>
                  {Array.from({ length: 12 }, (_, month) => (
                    <option key={month + 1} value={month + 1}>
                      เดือน {month + 1}
                    </option>
                  ))}
                </select>


                <select
                  className="form-select"
                  value={filterRound}
                  onChange={(e) => setFilterRound(e.target.value)}
                >
                  <option value="all">ตัดรอบ</option>
                  <option value="1">รอบ 1 ของเดือน(วันที่ 15)</option>
                  <option value="2">รอบ 2 ของเดือน(วันที่ 1)</option>
                </select>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="d-flex mb-3">
                <button className="btn btn-primary me-2" onClick={handleFilter}>
                  Apply Filters
                </button>
                <button className="btn btn-secondary" onClick={handleReset}>
                  Clear Filters
                </button>
              </div>

              {/* Display filtered rounds */}
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Year</th>
                    <th>Month</th>
                    
                    <th>Round</th>
                    <th>Done</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRounds.map((round, index) => (
                    <tr key={index}>
                      <td>{round["base-payment-rounds-current"]}</td>
                      <td>{round.transfer_year}</td>
                      <td>{round.transfer_month}</td>
                      
                      <td>{round.round}</td>
                      <td>{round.done ? "Yes" : "No"}</td>
                      <td>
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            setMode("Transfer");
                            setFilterMonth(round.transfer_month.toString());
                            setFilterRound(round.round.toString());
                            handleFilter();
                          }}
                        >
                          จัดการ
                        </button>

                        {/* ปุ่มอัพเดท done */}
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            Swal.fire({
                              title: "ยืนยันการทำเครื่องหมายว่าเสร็จสิ้น",
                              showCancelButton: true,
                              confirmButtonText: "Confirm",
                              cancelButtonText: "Cancel",
                              preConfirm: () => {
                                Swal.showLoading();

                                return fetch(
                                  "https://artelu-api.vercel.app/api/admin/backEndManager/donPaymentRound",
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                      "base-payment-rounds-current":
                                        round["base-payment-rounds-current"], // เปลี่ยนจาก basePaymentRoundsCurrent เป็น base-payment-rounds-current
                                    }),
                                  }
                                )
                                  .then((response) => response.json())
                                  .then((data) => {
                                    Swal.fire(
                                      "Success",
                                      "ทำเครื่องหมายรอบนี้ว่าเสร็จสิ้นเรียบร้อย!",
                                      "success"
                                    ).then(() => {
                                      // รีเฟรชข้อมูลเมื่อทำการอัพเดทเสร็จ
                                      fetchPaymentRounds();
                                    });
                                  })
                                  .catch((error) => {
                                    Swal.fire(
                                      "Error",
                                      "เกิดข้อผิดพลาดในการอัพเดทสถานะ",
                                      "error"
                                    );
                                  });
                              },
                            });
                          }}
                        >
                          เสร็จสิ้น
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {/* โหมด Transfer */}
          {mode === "Transfer" && (
            <>
              <div className="mb-5">
                <button
                  className="btn btn-secondary"
                  onClick={() => setMode("ManagerPaymentCurrent")}
                >
                  Back to Manager Payment
                </button>
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="ค้นหาโดย Referral Code"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="btn btn-secondary" onClick={handleSearch}>
                  ค้นหา
                </button>
              </div>

              {/* แสดงรายการ Transfer */}
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Referral Code</th>
                    <th>Income Current</th>
                    <th>Bank Name</th> {/* คอลัมน์สำหรับชื่อธนาคาร */}
                    <th>Bank Account</th>{" "}
                    {/* คอลัมน์สำหรับหมายเลขบัญชีธนาคาร */}
                    <th>User Name</th> {/* คอลัมน์สำหรับชื่อผู้ใช้ */}
                    <th>Phone Number</th> {/* คอลัมน์สำหรับเบอร์โทรผู้ใช้ */}
                    <th>Transfer Approve</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData
                    .filter((item) => {
                      if (!item.income_current) return false;

                      const incomeKeys = Object.keys(item.income_current);
                      const hasMatchingIncome = incomeKeys.some((key) => {
                        const income = item.income_current[key];
                        const monthMatches =
                          filterMonth === "all" ||
                          income.month === parseInt(filterMonth);
                        const roundMatches =
                          filterRound === "all" ||
                          income.payment_round === parseInt(filterRound);
                        return monthMatches && roundMatches;
                      });

                      return (
                        item.verify === true &&
                        item.status === true &&
                        hasMatchingIncome
                      );
                    })
                    .map((item, index) => {
                      return (
                        <tr key={item["refferal-code"]}>
                          <td>{item["refferal-code"]}</td>
                          <td>{renderIncomeCurrent(item.income_current)}</td>
                          <td>{item.bank?.bankName || "N/A"}</td>{" "}
                          {/* แสดงชื่อธนาคาร */}
                          <td>{item.bank?.bankAccount || "N/A"}</td>{" "}
                          {/* แสดงหมายเลขบัญชีธนาคาร */}
                          <td>{item.user?.name || "N/A"}</td>{" "}
                          {/* แสดงชื่อผู้ใช้ */}
                          <td>{item.user?.phoneNumber || "N/A"}</td>{" "}
                          {/* แสดงเบอร์โทรผู้ใช้ */}
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                Swal.fire({
                                  title: "ยืนยันการโอน",
                                  showCancelButton: true,
                                  confirmButtonText: "Confirm",
                                  cancelButtonText: "Cancel",
                                  preConfirm: () => {
                                    Swal.showLoading();

                                    return fetch(
                                      "https://artelu-api.vercel.app/api/admin/backEndManager/approve/transfer",
                                      {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                          refferalCode: item["refferal-code"],
                                        }),
                                      }
                                    )
                                      .then((response) => response.json())
                                      .then((data) => {
                                        Swal.fire(
                                          "Success",
                                          "โอนสำเร็จ!",
                                          "success"
                                        ).then(() => {
                                          fetchReferralData();
                                        });
                                      })
                                      .catch((error) => {
                                        Swal.fire(
                                          "Error",
                                          "เกิดข้อผิดพลาดในการโอน",
                                          "error"
                                        );
                                      });
                                  },
                                });
                              }}
                            >
                              Approve Transfer
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BackEndManager;
