import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "./RedirecUrlComponent.css";

const RedirecUrlComponent: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("RedirecUrlComponent");

    // รอให้ Line Login ทำงานเสร็จ (หน่วงเวลาเล็กน้อย)
    const timer = setTimeout(() => {
      const storedUrl = localStorage.getItem("redirectUrl"); // ดึง URL ที่เก็บไว้
      if (storedUrl) {
        localStorage.removeItem("redirectUrl"); // ลบ URL หลังจากใช้งาน
        window.location.href = storedUrl; // Redirect ไปยัง URL ที่เก็บไว้
      } else {
        console.error("No redirect URL found in localStorage.");
        navigate("/"); // กรณีที่ไม่มี URL ให้กลับไปหน้าแรก
      }
    }, 2000); // หน่วงเวลา 1.5 วินาที

    return () => clearTimeout(timer); // ล้าง Timer หาก component ถูกถอดออก
  }, [navigate]);

  const bounceTransition = {
    y: {
      duration: 0.4,
      repeat: Infinity, // แอนิเมชัน loop
      repeatType: "reverse", // เด้งขึ้นลง (กลับไปกลับมา)
      ease: "easeOut",
    },
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <h6>กำลังเปลี่ยนเส้นทาง...</h6>
      <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
        {[0, 1, 2, 3].map((index) => (
          <motion.span
            key={index}
            style={{
              display: "block",
              width: "15px",
              height: "15px",
              backgroundColor: "#efe8f5",
              borderRadius: "50%",
            }}
            animate={{
              y: ["0%", "-50%", "0%"], // จุดจะเด้งขึ้นลง
            }}
            transition={{
              ...bounceTransition,
              delay: index * 0.2, // เพิ่มความล่าช้าให้แต่ละจุด
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RedirecUrlComponent;
